import React, {useEffect, useState} from 'react'
import Layout from '../layout'
import RegisterForm from '../components/register/form'
import RegisterTitle from '../components/register/title'
import PictureWrapper from '../components/picture-wrapper'

const RegisterHeader = React.memo(
  function RegisterHeader() {
    return (
      <div className="w-full h-10 sm:h-18 md:h-20 lg:h-24 xl:h-36">
        <PictureWrapper
          sources={[
            {
              srcSet: `${process.env.PUBLIC_URL}/img/header.avif`,
              type: 'image/avif'
            },
            {
              srcSet: `${process.env.PUBLIC_URL}/img/header.webp`,
              type: 'image/webp'
            }
          ]}
          className="object-cover h-full w-full"
          src={`${process.env.PUBLIC_URL}/img/header.jpg`}
        />
      </div>
    )
  },
  () => true
)
const RegisterFooter = React.memo(
  function RegisterFooter() {
    return (
      <div className="">
        <PictureWrapper
          sources={[
            {
              srcSet: `${process.env.PUBLIC_URL}/img/footer.avif`,
              type: 'image/avif'
            },
            {
              srcSet: `${process.env.PUBLIC_URL}/img/footer.webp`,
              type: 'image/webp'
            }
          ]}
          className="object-cover h-full w-full"
          src={`${process.env.PUBLIC_URL}/img/footer.jpg`}
        />
      </div>
    )
  },
  () => true
)

function Register() {
  const [queryRefParam, setQueryRefParam] = useState('')
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params && params.ref) {
      setQueryRefParam(atob(params.ref))
    }
  }, [])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false)
  return (
    <React.Fragment>
      {!isValid ? (
        <div className="flex flex-col h-screen">
          <RegisterHeader />
          <div className="flex-auto flex flex-col md:justify-center mx-4 md:max-w-xl md:mx-auto xl:mx-auto space-y-5">
            <RegisterTitle />
            {queryRefParam ? (
              <RegisterForm
                name={name}
                email={email}
                setName={setName}
                setEmail={setEmail}
                setIsValid={setIsValid}
              />
            ) : (
              <h2 className="text-center font-bold text-2xl text-red-500 bg-red-50 py-2 rounded-lg">
                Lien invalide!
              </h2>
            )}
          </div>
          <RegisterFooter />
        </div>
      ) : (
        <Layout
          setIsValid={setIsValid}
          name={name}
          email={email}
          odooUser={queryRefParam}
        />
      )}
    </React.Fragment>
  )
}

export default Register
