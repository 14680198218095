import React from 'react'

function isEmail(val) {
  let regEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!regEmail.test(val)) {
    return false
  }
  return true
}
function RegisterForm({name, email, setName, setEmail, setIsValid}) {
  return (
    <div className="flex flex-col">
      <div className="py-2">
        <input
          type="text"
          name="name"
          placeholder="Votre nom"
          className="w-full h-full mb-1 rounded-sm"
          onChange={(evt) => setName(evt.target.value)}
        />
      </div>
      <div className="py-2">
        <input
          type="email"
          name="email"
          className="w-full h-full mb-1 rounded-sm"
          placeholder="Votre E-mail"
          onChange={(evt) => setEmail(evt.target.value)}
        />
      </div>
      <div className="py-2">
        <button
          className="text-md px-3 py-2 btn-test text-white hover:bg-yellow-700 w-full h-full rounded-sm"
          onClick={() => {
            if (name !== '' && isEmail(email)) {
              setIsValid(true)
            }
          }}
        >
          COMMENCER LE TEST
        </button>
      </div>
    </div>
  )
}

export default RegisterForm
