import React from 'react'
import {ClockIcon} from '@heroicons/react/solid'
function RegisterTitle() {
  return (
    <div className="flex flex-col space-y-3">
      <div className="text-lg sm:text-2xl md:text-xl lg:text-3xl xl:text-4xl m-1 text-center text-register font-bold">
        LA BOUSSOLE DU VOYAGE
      </div>
      <div className="text-md sm:text-xl md:text-md lg:text-xl xl:text-xl text-center m-1 text-register">
        <div className="flex items-center justify-center">
          <div className="mr-1">
            <ClockIcon className="w-6 h-6" />
          </div>
          <div>12 MIN</div>
        </div>
      </div>
      <p className="text-justify text-md md:font-bold my-2">
        Ce test a pour but de mieux vous conna&icirc;tre en tant que voyageu,r
        et de nous aider &agrave; dessiner votre voyage id&eacute;al.&#10;
        <br />
        Vous y trouverez plusieurs questions qui peuvent avoir l&apos;air
        farfelues, mais qui nous permettront de d&eacute;couvrir votre profil
        voyageur et votre profil sensoriel.&#10;
        <br />
        Ainsi si vous voyagez en groupe ou en famille, n&apos;h&eacute;sitez pas
        &agrave; le faire remplir &agrave; tous les participants.
      </p>
      <p className="text-justify text-md md:font-bold my-2">
        Vous recevrez les r&eacute;sultats sur votre bo&icirc;te mail, qui sont
        automatiquement partag&eacute;s avec l&apos;agent de voyage qui vous
        l&apos;a envoy&eacute;.&#10;
        <br />
        Absolument aucune information n&apos;est conserv&eacute;e sur ce
        serveur.
      </p>
      <div className="text-xs text-justify my-1 text-gray-700">
        {`Répondez de la manière la plus juste possible Une fois votre choix
effectué la question d'après apparait automatiquement`}
      </div>
    </div>
  )
}

export default RegisterTitle
