import {groupBy, prop, sum} from 'ramda'
import profiles from '../data/profiles'

function getMaxScore(arrayOfScores) {
  const maxScore = arrayOfScores.reduce(
    (max, character) => (character.score > max ? character.score : max),
    arrayOfScores[0].score
  )
  const searchObj = arrayOfScores.filter((d) => d.score === maxScore)

  return searchObj
}

function getMaxScorePerGroup(groupByProfile) {
  return Object.keys(groupByProfile).reduce((newObj, key) => {
    newObj[key] = getMaxScore(groupByProfile[key])

    return newObj
  }, {})
}

function getProfile(scores) {
  const addScoresToProfile = profiles.map((profile) => ({
    ...profile,
    score: scores[profile.code]
  }))

  const groupByProfile = groupBy(prop('typeProfile'), addScoresToProfile)

  const results = getMaxScorePerGroup(groupByProfile)

  return results
}

function getAllScores(answer) {
  const results = groupBy(prop('profile'), answer.flat())

  const scores = Object.keys(results).reduce((newObj, key) => {
    newObj[key] = sum(results[key].map((answer) => answer.points))

    return newObj
  }, {})

  return getProfile(scores)
}

export default getAllScores
