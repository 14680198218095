import React from 'react'

function Answer({currentQuestion, position}) {
  return (
    <div className="w-full h-full text-xs md:text-lg lg:text-lg xl:text-xl my-1">
      {currentQuestion.answers[position].answer}
    </div>
  )
}

export default Answer
