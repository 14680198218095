/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
// import moveInBoussole from '../../utils/delay'
import Picture from '../picture'
import Answer from './answer'
import Separator from './separator'

function OneChoice({
  questions,
  currentQuestion,
  handleAnswerButtonClick,
  progressNumber,
  setProgressNumber
}) {
  const [activeOption1, setActiveOption1] = useState(false)
  const [activeOption2, setActiveOption2] = useState(false)
  const [currentPosition, setCurrentPosition] = useState('')
  return (
    <div className="flex flex-col md:max-w-3xl lg:max-w-4xl xl:max-w-5xl md:mx-auto">
      <div className="flex flex-col md:flex-row mx-1 md:mx-0">
        <div
          className={`cursor-pointer my-1 p-2 w-full flex flex-col text-lg border shadow-lg rounded-md md:mx-1 ${
            activeOption1 ? 'text-white' : 'text-question'
          } hover:text-white hover:bg-yellow-600 ${
            activeOption1
              ? 'bg-yellow-600 text-white border-transparent'
              : 'text-black border-gray-200'
          }`}
          onClick={() => {
            setCurrentPosition('0')
            setActiveOption1(true)
            setActiveOption2(false)
            setTimeout(() => {
              setProgressNumber(progressNumber + 3.5)
              handleAnswerButtonClick([
                {
                  question: `quiz-${currentQuestion + 1}`,
                  profile:
                    questions[currentQuestion].answers[Number(currentPosition)]
                      .profile,
                  points:
                    questions[currentQuestion].answers[Number(currentPosition)]
                      .points
                }
              ])
            }, 1000)
          }}
        >
          <Picture
            currentQuestion={questions[currentQuestion]}
            position={0}
            classNameDiv="h-full md:h-60 lg:h-80 xl:h-96 w-full"
            classNameImg="object-contain md:object-cover h-full md:h-60 lg:h-80 xl:h-96 w-full rounded-xl relative"
          />
          <Answer currentQuestion={questions[currentQuestion]} position={0} />
        </div>
        <Separator label="OU" />
        <div
          className={`cursor-pointer my-1 p-2 w-full flex flex-col text-lg border shadow-lg rounded-md md:mx-1 ${
            activeOption2 ? 'text-white' : 'text-question'
          } hover:text-white hover:bg-yellow-600 ${
            activeOption2
              ? 'bg-yellow-600 text-white border-transparent'
              : 'text-black border-gray-200'
          }`}
          onClick={() => {
            setCurrentPosition('1')
            setActiveOption1(false)
            setActiveOption2(true)
            setTimeout(() => {
              setProgressNumber(progressNumber + 3.5)
              handleAnswerButtonClick([
                {
                  question: `quiz-${currentQuestion + 1}`,
                  profile:
                    questions[currentQuestion].answers[Number(currentPosition)]
                      .profile,
                  points:
                    questions[currentQuestion].answers[Number(currentPosition)]
                      .points
                }
              ])
            }, 1000)
          }}
        >
          <Picture
            currentQuestion={questions[currentQuestion]}
            position={1}
            classNameDiv="h-full md:h-60 lg:h-80 xl:h-96 w-full"
            classNameImg="object-contain md:object-cover h-full md:h-60 lg:h-80 xl:h-96 w-full rounded-xl relative"
          />
          <Answer currentQuestion={questions[currentQuestion]} position={1} />
        </div>
      </div>
    </div>
  )
}

export default OneChoice
