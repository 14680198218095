import React from 'react'
import {nanoid} from 'nanoid'
import {CheckCircleIcon} from '@heroicons/react/solid'

function CardMultipleAnswers({
  currentQuestion,
  position,
  checkedState,
  options,
  onClickCheck
}) {
  return (
    <div
      className={`flex justify-between items-center cursor-pointer h-full border rounded-md shadow-md md:ml-1 hover:bg-yellow-600 ${
        checkedState[position] ? 'text-white' : 'text-question'
      } hover:text-white focus:text-white ${
        position === currentQuestion.answers.length - 1
          ? 'mt-1 mb-0 md:mb-0'
          : 'mt-1 md:mb-1 md:mt-0'
      } p-2 ${
        checkedState[position]
          ? 'bg-yellow-600 text-white border-transparent'
          : 'text-black border-gray-200'
      }`}
      key={`options-${nanoid(12)}`}
      onClick={(evt) => onClickCheck(evt, position, options)}
      value={options.profile}
    >
      <div className="text-xs md:text-md lg:text-lg xl:text-xl w-11/12">
        <div>{options.answer}</div>
      </div>
      <div>
        <CheckCircleIcon
          className={`h-5 w-5 sm:h-6 sm:w-6 md:h-6 md:w-6 lg:h-8 lg:w-8  ${
            checkedState[position] ? 'bg-yellow-600' : 'text-transparent'
          }`}
        />
      </div>
    </div>
  )
}

export default CardMultipleAnswers
