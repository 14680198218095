export default [
  {
    code: 'V',
    name: 'Visuel',
    score: 0,
    typeProfile: 'sensorial-profile',
    results: {
      title: 'Visuel',
      description: `Le visuel a un sens de l’observation très développé. Toujours plein d’idées, il est créatif et sensible à ce qui l'entoure. Pour bien comprendre, il a besoin de voir de ses propres yeux ce qu’on lui dit. Un peu maniaque sur les bords, chez lui tout doit être rangé. Comme il ne supporte pas le désordre, le visuel est un collaborateur sur qui on peut compter sur son organisation au sein de l’équipe.

Souvent, quelques secondes suffisent pour qu’il se fasse une opinion sur la personne qu’il a en face de lui. Il fait attention à son apparence et il aime ça.

En voyage, il n’aura pas l'occasion de se perdre car il a le sens de l’orientation. En un coup d'œil il saura quel chemin prendre puisqu’il était indiqué sur la carte. Ce qui le fait vibrer ? Admirer un coucher de soleil et les paysages environnants.

En souvenir, il ramène un nouveau chapeau qui ira très bien avec sa tenue préférée et il montrera ses plus belles photos car il adore ça.

Pour ses hébergements, la vue depuis la fenêtre mais aussi la décoration des espaces communs ou le bâtiment sont essentiels à son bien être en voyage.
`,
      image: ''
    }
  },
  {
    code: 'K',
    name: 'Kinestésiste',
    score: 0,
    typeProfile: 'sensorial-profile',
    results: {
      title: 'Kinestésiste',
      description: `Le kinesthésique est sensible à ce qui l’entoure, aux ambiances et à l’atmosphère d’un lieu. Il est de nature chaleureuse et sympathique. Il aime mettre les gens à l’aise afin qu’ils se sentent bien. Avenant, il fait facilement des rencontres. Ses amis le qualifient de jovial et d’agréable et il leur rend bien car il est une personne fidèle. Le kinesthésique sait ce qui est bon pour lui ou pas, mais ses émotions l’envahissent souvent.

En voyage, il est important pour lui d’aller au contact de l’autre pour en apprendre plus. Il n’aura d’ailleurs aucun mal à demander son chemin à un inconnu dans la rue. Son voyage se construit comme une chasse aux trésors car il est toujours à la recherche de souvenirs inoubliables. Il fait donc tout au feeling car il suit son instinct.

Concernant les hébergements, il est plus difficile de choisir en amont mais quelques tips : l'accueil doit être chaleureux, convivial presque familial.

En rentrant de voyage, il associe ses souvenirs à une odeur, une émotion, un goût ou encore une atmosphère.
`,
      image: ''
    }
  },
  {
    code: 'A',
    name: 'Auditif',
    score: 0,
    typeProfile: 'sensorial-profile',
    results: {
      title: 'Auditif',
      description: `L’auditif est attentif aux sons et aux bruits environnants et les retient facilement. Enfant, pour apprendre une leçon, il aimait la répéter plusieurs fois afin de l’apprendre par cœur. On le qualifiait même de "pipelette". Lorsqu’il communique avec les autres, il fait attention aux mots qu’il emploie, il sait donc écouter les autres et s’écouter afin de suivre son intuition. En règle générale, il reconnaît difficilement les visages, cependant il sait instantanément qui est au bout du fil. L’auditif est une personne qui réfléchit longuement avant d’agir et préfère être sûr de son coup avant d’entreprendre.

En voyage, il est sensible à ce qu’il entend. Il aime donc écouter des groupes de musiques locaux ou tout simplement le chant des oiseaux au réveil.

Dans le choix des hébergements, il faudra être vigilant à l’environnement sonore, le bruit des vagues trop fort, une rue un peu passante peuvent être rédhibitoire pour ce voyageur à l'ouïe fine.

Il n’hésite pas à aller aborder les locaux pour pouvoir communiquer avec eux et échanger sur leurs coutumes. Lors de son retour, il sera le premier à raconter ses anecdotes vécues.
`,
      image: ''
    }
  },
  {
    code: 'DI',
    name: 'Dialogue Intérieur',
    score: 0,
    typeProfile: 'sensorial-profile',
    results: {
      title: 'Dialogue Intérieur',
      description: `Le Dialogue Intérieur est une personne posée et réfléchie. C’est un analyste et donc toujours à la recherche de solutions pour résoudre ses problèmes. De nature pensive, on dit de lui qu’il est toujours ailleurs, ce qui l’empêche de profiter du moment présent. En général, la raison l’emporte sur la passion et tout ce qu’il fait doit être concret.

On dit souvent de lui qu’il est une force tranquille à condition que tout se passe comme il l’avait imaginé. Le dialogue intérieur aime la simplicité car c’est ce qui lui correspond le mieux.

En voyage il n’agit pas sans raison, il ne souhaite pas se mettre en danger pour vivre une expérience unique. Il aime le calme et la tranquillité et souhaite découvrir sans trop sortir de sa zone de confort. Il connaît ses limites, il ne s’empêche donc pas de partir explorer et de faire des activités hors des sentiers battus à condition que la sécurité soit au rendez-vous.

Le dialogue intérieur aime particulièrement être au contact de la culture et des coutumes du pays qu’il visite. Son voyage sera donc plutôt dans la simplicité et se voudra rassurant.`,
      image: ''
    }
  },
  {
    code: 'LO',
    name: 'Local Obssessionnel',
    score: 0,
    typeProfile: 'traveler-profile',
    results: {
      title: 'LE LOCAL OBSESSIONNEL',
      description: `Votre citation : "Celui qui voyage sans rencontrer l’autre ne voyage pas, il se déplace" - Alexandra David-Néel

Le caméléon par excellence !

Le voyage du local obsessionnel doit avoir du sens et sa priorité est de rencontrer les locaux. Ce qu’il souhaite c’est l’immersion, la vraie ! Sur le pays, tout est bon à savoir :  Que ce soit sur l’histoire, la boisson incontournable au moment de l’apéro ou encore les coutumes pratiquées. On le retrouve souvent dans des marchés typiques ou chez l’habitant en train de partager un bon repas. Le local obsessionnel veut  apprendre, découvrir mais surtout se fondre dans la masse. Une fois plongé dans le pays, il n’est pas l’étranger d’Albert Camus mais l’Alchimiste de Paulo Coelho !

De nature joviale, il adore faire des rencontres imprévues et ce peu importe les conditions. Il est curieux et adore poser des questions sur la vie des locaux qui vivent bien différemment de lui. Flâner est son art de vivre; il aime se laisser surprendre par ce qui va arriver.

En règle générale, le local obsessionnel s’adapte à tout et rien ne l’arrête dans sa quête de l’autre.`,
      image: '/img/profiles/obsessionnel.png'
    }
  },
  {
    code: 'SPO',
    name: 'Sportif',
    score: 0,
    typeProfile: 'traveler-profile',
    results: {
      title: 'LE SPORTIF',
      description: `Votre citation : "Les défis rendent le voyage intéressant, les surmonter lui donne un sens" - Anne Onyme

Si monter tout en haut du Mont Blanc, gravir le temple aux 1001 marches en Thaïlande et faire un trek de 3 jours au fin fond de l’Amazonie ne vous fait pas peur, alors c’est que vous faites parti des rares voyageurs ayant le besoin irrépréssible de se dépenser. Addict aux activités sportives et aux sensations fortes, pour lui le voyage c’est surtout l’occasion de se challenger, de se dépasser et de se lancer des défis.

Paddle, roller, trek, quad, nage avec les dauphins… toutes les excuses sont bonnes pour entraîner sa tribu avec lui. Hyperactif il ne s'arrête jamais, amoureux de la nature il préférera largement les activités en plein air que les visites assommantes (selon lui) du musée de la charentaise par exemple. Le voyageur sportif court d’activités en activités, il est dynamique et acteur de son voyage : rien de doit être laissé au hasard. Parfois imprévisible ou imprudent, son énergie doit être canalisée lors d’activités encadrées par un guide qualifié.`
    },
    image: '/img/profiles/sportif.png'
  },
  {
    code: 'EXC',
    name: 'Exceptionnel',
    score: 0,
    typeProfile: 'traveler-profile',
    results: {
      title: "L'EXCEPTIONNEL",
      description: `Votre citation : "La vie ce n’est pas seulement respirer c’est avoir le souffle coupé" - Alfred Hitchcock

Nous avons tous cette personne dans nos amis, vous voyez le genre d’ami qui part tous les étés à St Tropez, qui squatte les plages privées coupe de Ruinart à la main, qui s’affiche sur les réseaux et qui laisse penser à tout le monde que ses vacances sont bien plus incroyables que les tiennes ? Cette personne, tu la connais, je la connais, on la connaît tous.

Le voyageur exceptionnel n’est pas que "show-off", c’est aussi un épicurien, amateur des beaux endroits et des lieux exceptionnels. En voyage il reste égal à lui-même, il préfère largement mettre un budget conséquent pour un voyage dont il se souviendra toute sa vie. Il n’aime pas particulièrement sortir de sa zone de confort et préfère un voyage organisé en prenant son temps pour profiter de chaque étape.

L’exceptionnel aime les activités insolites et extraordinaires.

Il aime raconter à ses amis le survol de New York en hélicoptère lors de son séjour ou lorsqu’il a dormi dans une bulle transparente au fin fond du Costa Rica pour admirer les étoiles filantes. Pour lui chaque voyage est unique et doit être savouré à pleine dent : hors de question de se restreindre et de partir avec un petit budget. Tout doit être fabuleux, son voyage ne doit ressembler à aucun autre, surtout pas à celui de Jean Pierre, son voisin partit au Costa Rica la même année.`,
      image: '/img/profiles/exceptionnel.png'
    }
  },
  {
    code: 'SA',
    name: 'Sage',
    score: 0,
    typeProfile: 'traveler-profile',
    results: {
      title: 'LE SAGE',
      description: `Votre citation : "Le plus grand voyageur n'est pas celui qui a fait 10 fois le tour du monde. Mais celui qui a fait 1 seule fois le tour de lui-même" -  Gandhi.

En parfaite harmonie avec la nature et ce qui l’entoure, le sage est particulièrement sensible à son environnement. Il aime prendre soin de son corps et surtout de son âme.

En voyage, il aime les lieux calmes, proches de la nature, voire excentrés et isolés. A la recherche d’une certaine paix intérieure, le voyage pour lui est synonyme de repos, de méditation et de quête de soi. Pour le sage, la quête de soi passe aussi par la quête de l’autre, il fera donc tout pour vivre de la même manière que les locaux - se fondre dans la masse est son objectif.

Il n’aime pas vexer les locaux avec qui ils tissent des liens, ainsi, s’il a un régime alimentaire en particulier, il n'hésitera pas à l'entraver pour ne pas les froisser.

Solitaire, il passe la plupart de son temps à faire des activités seul comme la lecture, la randonnée, la méditation, l’exploration, loin des visiteurs de masse et lieux ultra-touristiques.

Le sage ne voyage pas comme tout le monde, l’attractivité touristique n’a aucune importance quant au choix de la destination, il privilégie les destinations avec une identité forte, parsemées de lieux sacrés, facilitant le voyage spirituel à la recherche de son "moi intérieur".`,
      image: '/img/profiles/sage.png'
    }
  },
  {
    code: 'F',
    name: 'Farniente',
    score: 0,
    typeProfile: 'traveler-profile',
    results: {
      title: 'LE FARNIENTE',
      description: `Votre citation : "Etre en vacances c’est n’avoir rien à faire et avoir toute la journée pour le faire" - Robert Orben

Vous voyez la carte postale ? Celle qui fait rêver : une eau cristalline, une plage de sable fin, des cocotiers. Bref le paradis des voyageurs amoureux du farniente. Vous aussi quand vous fermez les yeux, cette image vous vient en tête ? Félicitations ! Vous faites partie du clan très privilégié des voyageurs farniente. Ceux pour qui voyage rime avec détente. Les farniente aiment se laisser porter.

Leur voyage doit être organisé, fluide et sans embûches en évitant tout facteur de stress. Rien ne doit venir perturber sa paisibilité.

Épicurien, le farniente aime profiter de son voyage pour savourer des moments uniques. Il privilégie généralement les hébergements proches de la plage ou de la piscine afin d’en profiter au maximum. Il aime ne rien faire et ne se soucie de rien pendant son séjour, c’est pourquoi il aime les formules tout inclus ou les hébergements permettant de se restaurer facilement.

Dans les familles des farniente, on aime particulièrement sa tranquillité et c’est pourquoi on voyage généralement seul, en couple ou en petits groupes d’amis (option sans enfants généralement). Ceci dit, le farniente aime également sortir et profiter.

Les destinations qui le font rêver sont généralement ensoleillées et éloignées des centres villes, il n’apprécie pas particulièrement les activités sportives ou culturelles mais il aime, quand l’envie le prend, une visite de musée ou une balade en canoë de temps en temps. `,
      image: '/img/profiles/farniente.png'
    }
  },
  {
    code: 'A',
    name: 'Aventurier',
    score: 0,
    typeProfile: 'traveler-profile',
    results: {
      title: "L'AVENTURIER",
      description: `Votre citation : "On ne va jamais aussi loin que lorsqu'on ne sait pas où l’on va" - Christophe Colomb

Égaré dans la vallée infernale!

Le héros de l’aventurier est celui qui recherche l’aventure, la vraie… Celle qui est faite d’imprévus et de surprises. Pour l’aventurier, pas d’alternatives :  il faut voyager hors des sentiers battus. D’ailleurs, il est indépendant mais il n’est pas contre un guide local lorsqu'il y a un tas de choses à apprendre ou lorsque la logistique le nécessite.

Explorer le passionne, et outre le fait de vivre des expériences, ce qu’il veut c’est rencontrer l’autre ! Un peu d’authenticité s’il vous plaît !

Curieux, l’aventurier n’a peur de rien ni de personne. Il n’aime en aucun cas la routine et chaque jour doit être une découverte. Souvent, on qualifie l’aventurier de baroudeur et c’est plein d’enthousiasme qu’il est prêt à plonger dans ce beau voyage ! En avant !`,
      image: '/img/profiles/aventurier.png'
    }
  },
  {
    code: 'C',
    name: 'Culturel',
    score: 0,
    typeProfile: 'traveler-profile',
    results: {
      title: 'LE CULTUREL',
      description: `Votre citation : "Il faut voyager pour apprendre" - Mark Twain

Sa plus grande peur ? Glisser sur un livre qu’il n’a pas encore lu.

Le culturel c’est la personne qui n’est pas là par hasard, il sait ce qu’il y a voir dans les villes qu’il découvre et avec un peu de chance, il s’est déjà beaucoup renseigné sur l’artiste en question. Van Gogh, Monet, Picasso, l'intéressent mais il veut aussi être surpris : découvrir de nouveaux lieux, de nouveaux artistes et de nouvelles œuvres. A quoi bon voyager dans ce cas ?

Musées, cathédrales, expositions, théâtres, spectacles, cinémas : il veut tout voir et tout comprendre de la culture locale. Il aimera autant aller voir les fameuses statues de l’île de Pâques que le dernier chef d'œuvre d’un artiste local.

Le culturel est patient et peut rester des heures au même endroit tant qu’il s’y sent bien et qu’il découvre des tas de choses. Il est avenant, sociable mais doit impérativement avoir ses moments de paix pour mieux admirer ce qu’il voit. Il est curieux et ne peut s’empêcher de se poser des questions. Le soir, en rentrant de sa journée, il partage ses découvertes sur ce qu’il a vu et vécu. C’est sa façon à lui de transmettre son apprentissage du jour.

Avec lui, l’histoire de l’art et de la culture ne disparaîtra jamais (et nous l’en remercions).`,
      image: '/img/profiles/culturel.png'
    }
  },
  {
    code: 'ENT',
    name: 'Enthousiaste',
    score: 0,
    typeProfile: 'traveler-profile',
    results: {
      title: "L'ENTHOUSIASTE",
      description: `Votre citation : "Waouh j’ai jamais rien vu d’aussi beau" - Enthousiaste célèbre

Voyager ? L’enthousiaste en a rêvé toute sa vie, et faire ses valises l’enthousiasme déjà.

Il ne voyage pas souvent, alors celui-ci c'est le sien et il veut profiter de tout ! L’enthousiaste est un voyageur sans prise de tête, à la cool. Il a l'habitude de voyager et n’est donc pas stressé quant à l’idée de quitter son cocon familial. En revanche, il a tellement voulu ce séjour au Pérou qu’il veut tout voir !

Du repas chez l’habitant, à l’hôtel de luxe avec spa en passant par tous les sites incontournables, il veut vivre son expérience à fond.

Il est généralement de nature gaie et volontaire, il se laisse volontiers convaincre par son guide pour des activités supplémentaires. Il a planifié son séjour à l’avance et ne laisse pas trop place aux imprévus mais tout lui convient, l’important c’est de profiter.

L’enthousiaste fait partie des voyageurs heureux et faciles, il s’adapte aisément. L’important c’est l’expérience, peu importe le chemin pour y arriver.`,
      image: '/img/profiles/enthousiaste.png'
    }
  }
]
