import React from 'react'
import PictureWrapper from './picture-wrapper'

function Picture({currentQuestion, position, classNameDiv, classNameImg}) {
  return (
    <div className={classNameDiv}>
      {currentQuestion.images && currentQuestion.images.length > 0 && (
        <PictureWrapper
          sources={[
            {
              srcSet: currentQuestion.images[position].replace(/jpg$/, 'avif'),
              type: 'image/avif'
            },
            {
              srcSet: currentQuestion.images[position].replace(/jpg$/, 'webp'),
              type: 'image/webp'
            }
          ]}
          className={classNameImg}
          src={currentQuestion.images[position]}
        />
      )}
    </div>
  )
}

export default Picture
