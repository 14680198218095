import React from 'react'
import {Picture as BasePicture} from 'react-responsive-picture'
import {useApp} from '../context'

function PictureWrapper({sources, ...props}) {
  const {webp, safariWithBug} = useApp()
  const getWebP = safariWithBug && webp && sources.length > 0
  let webPImg = getWebP
    ? sources.filter(({type}) => type === 'image/webp')[0].srcSet
    : ''
  console.log(3)
  if (webPImg) {
    return <BasePicture {...props} src={webPImg} />
  }

  return (
    <BasePicture {...props} sources={safariWithBug ? undefined : sources} />
  )
}

export default PictureWrapper
