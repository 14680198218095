import React, {useEffect, useState} from 'react'
import MultipleChoice from '../components/multiple-choice'
import OneChoice from '../components/one-choice'
import ProgressBar from '@ramonak/react-progress-bar'

function Quiz({questions, currentQuestion, handleAnswerButtonClick}) {
  const [isLoadingQuiz, setIsLoadingQuiz] = useState(false)

  useEffect(() => {
    setIsLoadingQuiz(true)
    return () => {
      if (isLoadingQuiz) {
        setIsLoadingQuiz(false)
      }
    }
  })

  const [progressNumber, setProgressNumber] = useState(3.5)

  return (
    <React.Fragment>
      {isLoadingQuiz && (
        <div className="flex flex-col">
          {' '}
          <div className="text-xs sm:text-md md:text-lg lg:text-xl xl:text-2xl font-bold m-2 text-center text-gray-900 text-quiz-green md:max-w-2xl lg:max-w-3xl xl:max-w-4xl md:mx-auto">
            {questions[currentQuestion].question}
          </div>
          <div>
            {questions[currentQuestion].typeOfQuestion === 'simple' ? (
              <OneChoice
                questions={questions}
                currentQuestion={currentQuestion}
                handleAnswerButtonClick={handleAnswerButtonClick}
                progressNumber={progressNumber}
                setProgressNumber={setProgressNumber}
              />
            ) : (
              <MultipleChoice
                questions={questions}
                currentQuestion={currentQuestion}
                handleAnswerButtonClick={handleAnswerButtonClick}
                progressNumber={progressNumber}
                setProgressNumber={setProgressNumber}
              />
            )}
          </div>
          <div className="fixed left-0 bottom-0 w-full">
            <ProgressBar
              completed={progressNumber}
              bgColor="#cf542b"
              height="15px"
              borderRadius="0px"
              isLabelVisible={false}
              labelColor="#e80909"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Quiz
