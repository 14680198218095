import React, {useEffect, useState} from 'react'
import {nanoid} from 'nanoid'
import Picture from '../picture'
import CardMultipleAnswers from './card-multiple-answers'
// import moveInBoussole from '../../utils/delay'
// import BtnNextQuestion from './btn-next-question'

function MultipleChoice({
  questions,
  currentQuestion,
  handleAnswerButtonClick,
  progressNumber,
  setProgressNumber
}) {
  const [checkedState, setCheckedState] = useState([])

  useEffect(() => {
    const currentAnswers = questions[currentQuestion].answers
    setCheckedState(new Array(currentAnswers.length).fill(false))
  }, [])

  const [opt, setOpt] = useState([])

  function onClickCheck(evt, position, options) {
    evt.preventDefault()
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )
    setCheckedState(updatedCheckedState)
    // TODO: require a refactoring.
    // moveInBoussole()
    setTimeout(() => {
      handleAnswerButtonClick(opt)
      if (currentQuestion + 1 < questions.length) {
        setOpt([])
        const nextAnswers = questions[currentQuestion + 1].answers
        setCheckedState(new Array(nextAnswers.length).fill(false))
        setProgressNumber(progressNumber + 3.5)
      }
    }, 1000)
    if (!checkedState[position]) {
      setOpt((prevState) => [
        ...prevState,
        {
          question: `quiz-${currentQuestion + 1}`,
          profile: options.profile,
          points: options.points
        }
      ])
    } else {
      const newOptions = opt.filter((o) => o.profile !== options.profile)
      setOpt(newOptions)
    }
  }

  return (
    <div className="flex flex-col mx-2 md:max-w-2xl lg:max-w-4xl xl:max-w-5xl md:mx-auto">
      <div className="flex flex-col md:flex-row-reverse">
        <Picture
          currentQuestion={questions[currentQuestion]}
          position={0}
          classNameDiv="flex md:mx-1 md:w-6/12"
          classNameImg="object-cover w-full rounded-2xl relative"
        />
        <div className="flex flex-col md:w-6/12">
          {questions[currentQuestion].answers.map((options, index) => (
            <CardMultipleAnswers
              key={nanoid(12)}
              currentQuestion={questions[currentQuestion]}
              position={index}
              checkedState={checkedState}
              options={options}
              onClickCheck={onClickCheck}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MultipleChoice
