import React, {useEffect} from 'react'
// import {HomeIcon} from '@heroicons/react/solid'

function ResultsQuiz({
  results,
  // setCurrentQuestion,
  // setAnswer,
  // setIsValid,
  name,
  email,
  odooUser
}) {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const templateGuest = `
        <html>
          <head>
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Nunito&display=swap" rel="stylesheet">
            <style>
              .center {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
              }
            </style>
          </head>
          <body>
            <h2 style="font-size: 40px;font-weight: bold;">Salut ${name},</h2>
            <h3 style="font-size: 30px;font-weight: bold;">Voici les résultats du quiz:</h3>
            <img src="http://sample-quiz-togezer.s3-website-us-east-1.amazonaws.com${results['traveler-profile'][0].results.image}" width="350" title="${results['traveler-profile'][0].name}" class="center">
            <p style="font-size: 16px;">VOTRE PROFIL VOYAGEUR</p>
            <p style="font-size: 20px;font-weight: bold;">${results['traveler-profile'][0].name}</p>
            <p style="font-size: 14px;">${results['traveler-profile'][0].results.description}</p>
            <p style="font-size: 16px;">VOTRE PROFIL SENSORIEL</p>
            <p style="font-size: 20px;font-weight: bold;">${results['sensorial-profile'][0].name}</p>
            <p style="font-size: 14px;">${results['sensorial-profile'][0].results.description}</p>
          </body>
        </html>
      `
      const odooUserTemplate = `
        <html>
          <head>
          </head>
          <body>
            <p style="font-size: 20px;font-weight: bold;">Bonjour!</p>
            <p style="font-size: 16px;font-weight: bold;">${name} (${email}) vient de remplir la Boussole du voyage et a obtenu les résultats suivants:</p>
            <p style="font-size: 16px;">profil voyageur:</p>
            <p style="font-size: 20px;font-weight: bold;">${results['traveler-profile'][0].name}</p>
            <p style="font-size: 14px;">${results['traveler-profile'][0].results.description}</p>
            <p style="font-size: 16px;">profil sensoriel:</p>
            <p style="font-size: 20px;font-weight: bold;">${results['sensorial-profile'][0].name}</p>
            <p style="font-size: 14px;">${results['sensorial-profile'][0].results.description}</p>
          </body>
        </html>
      `
      window.Email.send({
        SecureToken: process.env.REACT_APP_CLIENT_SECURE_TOKEN,
        To: email,
        From: process.env.REACT_APP_CLIENT_EMAIL,
        Subject: 'VOTRE PROFIL VOYAGEUR',
        Body: templateGuest
      })
        .then((message) => console.log(message, 'mail sent successfully'))
        .catch((e) => console.log(e))

      window.Email.send({
        SecureToken: process.env.REACT_APP_CLIENT_SECURE_TOKEN,
        To: odooUser,
        From: process.env.REACT_APP_CLIENT_EMAIL,
        Subject: `${name} vient de remplir la Boussole du voyage!`,
        Body: odooUserTemplate
      })
        .then((message) =>
          console.log(message, 'mail sent successfully for odoo user')
        )
        .catch((e) => console.log(e))
    }
  }, [])
  return (
    <div className="flex flex-col md:mx-auto max-w-2xl lg:max-w-5xl h-screen md:justify-center md:p-4">
      {/* <div>
        <button
          className="p-1 md:m-1 text-white text-xs md:text-md lg:text-lg xl:text-lg"
          style={{backgroundColor: '#d68a70'}}
          onClick={() => {
            setCurrentQuestion(0)
            setAnswer([])
            setIsValid(false)
          }}
        >
          <HomeIcon className="h-5 w-5 sm:h-6 sm:w-6 md:h-6 md:w-6 lg:h-8 lg:w-8" />
        </button>
      </div> */}
      <div className="uppercase text-md md:text-lg p-2 text-center">
        Vos Résultats
      </div>
      <div className="flex flex-col md:flex-row-reverse md:rounded-md md:shadow-lg md:border md:border-gray-200">
        <div className="md:w-4/12">
          <img
            className="object-contain object-top w-full h-full"
            src={`${process.env.PUBLIC_URL}${results['traveler-profile'][0].results.image}`}
          />
        </div>
        <div className="md:w-8/12">
          <div className="flex flex-col m-2">
            <div className="uppercase text-xs md:text-base p-1 text-center md:text-left">
              VOTRE PROFIL VOYAGEUR
            </div>
            <div className="font-bold uppercase text-base text-center md:text-left mx-1 md:text-lg">
              {results['traveler-profile'][0].name}
            </div>
            <div className="text-xs lg:text-base p-2 text-justify">
              {results['traveler-profile'][0].results.description}
            </div>
          </div>
          <div className="flex flex-col m-2">
            <div className="uppercase text-xs md:text-base p-1 text-center md:text-left">
              VOTRE PROFIL SENSORIEL
            </div>
            <div className="font-bold uppercase text-base text-center md:text-left mx-1 md:text-xl">
              {results['sensorial-profile'][0].name}
            </div>
            <div className="text-xs lg:text-base p-2 text-justify">
              {results['sensorial-profile'][0].results.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultsQuiz
