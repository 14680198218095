import React from 'react'

function Separator({label}) {
  return (
    <div className="p-0 md:p-2 text-center md:self-center">
      <span className="text-xs md:text-xl">{label}</span>
    </div>
  )
}

export default Separator
