import React from 'react'
const AppContext = React.createContext()
const AppProvider = AppContext.Provider

function useApp() {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error('useApp must be used within a CountProvider')
  }
  return context
}

export {AppProvider, useApp}
