import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import detect from 'webp-avif-browsers-support-detection'
import {AppProvider} from './context'
import {daumtools} from 'ua_parser'
let {
  browser: {
    name,
    version: {major}
  }
} = daumtools.userAgent()
// safari 14+ always load fallback `<img>` when react uses `<picture>` and `<source>` tags
const safariWithBug = name === 'safari' && Number(major) >= 14

detect().then(function (resp) {
  // will print the detils (ex. {avif:true, webp:false})
  setTimeout(() => {
    ReactDOM.render(
      <React.StrictMode>
        <AppProvider value={{...resp, safariWithBug}}>
          <App />
        </AppProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  }, 250)
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
