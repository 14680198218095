import React, {useState} from 'react'
import Quiz from './screens/quiz'
import questionsTogezer from './data/questions-togezer'
import getAllScores from './utils/get-all-scores'
import ResultsQuiz from './screens/results'
import {HomeIcon} from '@heroicons/react/solid'

function Layout({setIsValid, name, email, odooUser}) {
  const copyQuestions = questionsTogezer
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [showScore, setShowScore] = useState(false)
  const [answer, setAnswer] = useState([])

  function handleAnswerButtonClick(options) {
    setAnswer((prevAnswer) => [...prevAnswer, options])

    const nextQuestion = currentQuestion + 1

    if (nextQuestion < copyQuestions.length) {
      setCurrentQuestion(nextQuestion)
    } else {
      setShowScore(true)
    }
  }

  const results = getAllScores(answer)

  return (
    <React.Fragment>
      <div>
        <button
          className="p-1 text-white w-auto h-auto text-xs md:text-md lg:text-lg xl:text-lg fixed"
          style={{backgroundColor: '#d68a70'}}
          onClick={() => {
            setCurrentQuestion(0)
            setAnswer([])
            setIsValid(false)
          }}
        >
          <HomeIcon className="h-5 w-5 sm:h-6 sm:w-6 md:h-6 md:w-6 lg:h-8 lg:w-8" />
        </button>
      </div>
      <div className="flex flex-col xl:max-w-5xl xl:mx-auto h-screen md:justify-center">
        {!showScore ? (
          <React.Fragment>
            <div>
              <Quiz
                questions={copyQuestions}
                currentQuestion={currentQuestion}
                handleAnswerButtonClick={handleAnswerButtonClick}
              />
            </div>
          </React.Fragment>
        ) : (
          <ResultsQuiz
            results={results}
            setCurrentQuestion={setCurrentQuestion}
            setAnswer={setAnswer}
            setIsValid={setIsValid}
            name={name}
            email={email}
            odooUser={odooUser}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default Layout
